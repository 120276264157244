import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home/:link',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: ':link',
    redirectTo: 'home/:link',
    pathMatch: 'full'
  },
  {
    path: 'planos/:id/:planoCategoriaId',
    loadChildren: () => import('./pages/planos/planos.module').then(m => m.PlanosPageModule)
  },
  {
    path: 'planos/:id/:planoCategoriaId/:vendedorId',
    loadChildren: () => import('./pages/planos/planos.module').then(m => m.PlanosPageModule)
  },
  {
    path: 'planos-assistencias/:planoCategoriaId',
    loadChildren: () => import('./pages/planos-assistencias/planos-assistencias.module').then(m => m.PlanosAssistenciasPageModule)
  },
  {
    path: 'planos-assistencias/:planoCategoriaId/:link',
    loadChildren: () => import('./pages/planos-assistencias/planos-assistencias.module').then(m => m.PlanosAssistenciasPageModule)
  },
  {
    path: 'area-cliente/:id',
    loadChildren: () => import('./pages/area-cliente/area-cliente.module').then(m => m.AreaClientePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
