import { environment } from 'src/environments/environment';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  constructor() { }

  intercept(req: any, next: any) {
    const tokenizedReq = req.clone({
      setHeaders: {
        //authorization: 'Bearer ' + localStorage.getItem('token')
        apikey: '0ru58p7ww2f'
      }
    });
    return next.handle(tokenizedReq);
  }
}
